      
<template>
  <div>
    <vx-card>
      <div class="vx-row find-people">
        <!-- Prebuilt Campaigns table-->
        <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
          <div class="card-title more-card-title">
            <h2 class="mb-3">Prebuilt Campaigns</h2>
          </div>
          <div class="flex btn-search w-full flex-wrap pb-4 justify-start">
            <vs-button
              title="Add Campaign"
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-plus"
              class="mr-2 float-left mb-1"
              @click="addCampaignPopup = true,addEditCampaignTitle = 'Add Prebuilt Campaign'"
              >{{ LabelConstant.buttonLabelAddCampaign }} </vs-button
            >
            <vs-button
              title="Add Category"
              color="success"
              type="filled"
              icon-pack="feather"
              icon="icon-plus"
              class="mr-2 float-left mb-1"
              @click="categoryPopup = true"
              >{{ LabelConstant.buttonLabelAddCategory }}</vs-button
            >
          </div>
          <div class="common-table">
            <div class="table-row-select">
              <vs-select
                :label="LabelConstant.inputLabelShowEntries"
                v-model="perPage"
                class="show-select"
                autocomplete
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in recordList"
                />
              </vs-select>
            </div>
            <!-- table -->
            <vs-table
              ref="prebuiltCampaignTableData"
              :data="prebuiltCampaignsList"
              :max-items="perPage"
              pagination
              search
            >
              <template slot="thead">
                <vs-th sort-key="Campaign_Name">Name</vs-th>
                <vs-th sort-key="Category">Category</vs-th>
                <vs-th sort-key="Description">Description</vs-th>
                <vs-th sort-key="keywords">Keywords</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].Campaign_Name">
                    {{ data[indextr].Campaign_Name }}
                  </vs-td>
                  <vs-td :data="data[indextr].Category">
                    {{ data[indextr].Category }}
                  </vs-td>
                  <vs-td :data="data[indextr].Description">
                    {{ data[indextr].Description }}
                  </vs-td>
                  <vs-td :data="data[indextr].keywords">
                    {{ data[indextr].keywords }}
                  </vs-td>
                  <vs-td width="90">
                    <div class="table-action">
                      <vs-button
                        title="Edit"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-edit"
                        class="mr-2"
                        @click="getPrebuiltCampaign(data[indextr]),addEditCampaignTitle = 'Edit Prebuilt Campaign - ' + data[indextr].Campaign_Name"
                      ></vs-button>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
    </vx-card>
    <!-- Add Category Popup -->
    <vs-popup
      class=""
      :title="addEditCategoryTitle"
      :active.sync="addEditCategoryPopup"
    >
      <template>
        <form data-vv-scope="addEditCategoryForm">
          <div class="vx-row">
            <div class="vx-col mb-6 w-full">
              <p class="w-full float-left">Category Name</p>
              <div class="vx-row align-center">
                <div class="vx-col mb-6 w-full">
                  <vs-input
                    class="w-full"
                    name="addEditCategoryName"
                    v-model="addEditCategoryName"
                    v-validate="'required'"
                  />
                  <span class="text-danger text-sm mb-3 float-left w-full">{{ errors.first("addEditCategoryForm.addEditCategoryName") }}</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button
          title="Update"
          color="primary"
          type="filled"
          class="mr-2"
          @click="!editCategoryMode ? addCategory() : editCategory()"
          >{{ LabelConstant.buttonLabelSave }}</vs-button
        >
        <vs-button
          color="grey"
          @click="cancelAddEditCategory()"
          type="border"
          class="grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    <!-- Category Popup -->
    <vs-popup
      class="popup-width"
      title="Category List"
      :active.sync="categoryPopup"
    >
      <template>
        <div class="vx-row find-people">
          <!-- Category List table-->
          <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
            <div class="flex btn-search w-full pb-4 justify-start">
              <vs-button
                title="Add Category"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
                class="mr-2 float-left"
                @click="addEditCategoryPopup = true,addEditCategoryTitle = 'Add Category'"
                >{{ LabelConstant.buttonLabelAddCategory }}</vs-button
              >
            </div>
            <div class="common-table">
              <div class="table-row-select">
                <vs-select
                  :label="LabelConstant.inputLabelShowEntries"
                  v-model="perPageCategories"
                  class="show-select"
                  autocomplete
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in recordList"
                  />
                </vs-select>
              </div>
              <!-- table -->
              <vs-table
                :data="categoryList"
                ref="categoryTableData"
                :max-items="perPageCategories"
                pagination
                search
              >
                <template slot="thead">
                  <vs-th>Category Name</vs-th>
                  <vs-th>Actions</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tr"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <vs-td :data="data[indextr].Category_Name">
                      {{ data[indextr].Category_Name }}
                    </vs-td>
                    <vs-td width="90">
                      <div class="table-action">
                        <vs-button
                          title="Edit"
                          color="primary"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-edit"
                          class="mr-2"
                          @click="addEditCategoryPopup = true,editCategoryMode = true,addEditCategoryTitle = 'Edit Category',
                            addEditCategoryName = data[indextr].Category_Name,editCategoryId = data[indextr].Id"
                        ></vs-button>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </div>
        <div class="flex w-full float-left mt-6 mb-8">
          <vs-button
            color="grey"
            @click="categoryPopup = false"
            type="border"
            class="grey-btn ml-auto"
            >{{ LabelConstant.buttonLabelCancel }}</vs-button
          >
        </div>
      </template>
    </vs-popup>
    <!-- Add Campaign Popup -->
    <vs-popup
      class="popup-width intent-champain-popup  close-open"
      :title="addEditCampaignTitle"
      :active.sync="addCampaignPopup"
    >
      <vs-button
        @click="cancelCampaign()"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        >{{ LabelConstant.buttonLabelClose }}
      </vs-button>
      <template>
        <div class="campaign-second">
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="from-to-camp find-people">
                <form data-vv-scope="prebuiltCampaignForm">
                  <div class="vx-row">
                    <!-- Campaign Name -->
                    <div class="vx-col w-full mb-1">
                      <div class="filter-campaign">
                        <h3>Campaign Name</h3>
                        <vs-input
                          class="w-full "
                          v-model="campaignName"
                          v-validate="'required'"
                          name="campaignName"
                        />
                        <span class="text-danger text-sm mb-3 float-left w-full">{{ errors.first("prebuiltCampaignForm.campaignName") }}</span>
                      </div>
                    </div>
                    <!-- Description -->
                    <div class="vx-col w-full mb-1">
                      <div class="filter-campaign">
                        <h3>Description</h3>
                        <vs-textarea
                          class="form-textarea"
                          placeholder="Type here"
                          v-model="description"
                        />
                      </div>
                    </div>
                    <!-- Select Category -->
                    <!-- <div class="vx-col w-full mb-1">
                      <div class="filter-campaign">
                        <h3>Category</h3>
                        <div class="add-to-field">
                          <div
                            class="
                              float-left
                              flex
                              items-center
                              w-full
                              flex-wrap
                              mt-3
                            "
                          >
                            <v-select
                              class="w-full md:w-4/5"
                              label="Category_Name"
                              placeholder="Select"
                              :options="categoryList"
                              :reduce="(option) => option.Id"
                              v-model="category"
                              :clearable="false"
                            />
                            <vs-button
                              title="Update"
                              color="success"
                              class="mx-2 ml-auto"
                              type="filled"
                              @click="(addEditCategoryPopup = true),(addEditCategoryTitle = 'Add Category')"
                            >Add Category</vs-button>
                          </div>
                         
                        </div>
                      </div>
                    </div> -->
                    <!-- Select Category -->
                    <div class="vx-col w-full mb-1">
                      <div class="filter-campaign">
                        <h3>Category</h3>
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2 w-full">
                            <div class="add-to-field">
                              <v-select
                                class="w-full"
                                label="Category_Name"
                                placeholder="Select"
                                :options="categoryList"
                                :reduce="(option) => option.Id"
                                v-model="category"
                                :clearable="false"
                              />
                              <span class="text-danger text-sm mb-3 float-left w-full">{{ errors.first("prebuiltCampaignForm.category") }}</span>
                            </div>
                          </div>
                          <div class="vx-col md:w-1/2 w-full find-people">
                            <vs-button
                              title="Update"
                              color="success"
                              class="mx-2 ml-auto"
                              type="filled"
                              @click="addEditCategoryPopup = true,addEditCategoryTitle = 'Add Category'"
                              >{{ LabelConstant.buttonLabelAddCategory }}</vs-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Keywords -->
                    <div class="vx-col w-full mb-1">
                      <div class="filter-campaign">
                        <h3>Keywords</h3>
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2 w-full">
                            <div class="add-to-field">
                              <div class="float-left flex items-center w-full flex-wrap">
                                <v-select
                                  ref="keyworddd"
                                  class="w-full mb-3 input-color"
                                  label="Value"
                                  placeholder="Select "
                                  :options="keywordsOptions"
                                  v-model="keywordSelected"
                                  :clearSearchOnSelect="false"
                                  @input="clearSearchedTerm('keyword')"
                                  @keyup.native="getTiggerItemByTypeTerm('keyword', $event)"
                                />
                                <vs-button
                                  title="Update"
                                  color="primary"
                                  class="ml-auto"
                                  type="filled"
                                  @click="requestNewTrigger('keyword')"
                                  >{{ LabelConstant.buttonLabelRequestNewKeyword }}</vs-button
                                >
                                <vs-button
                                  title="Update"
                                  color="success"
                                  class="ml-2"
                                  type="filled"
                                  @click="addKeywords()"
                                  >{{ LabelConstant.buttonLabelAdd }}</vs-button
                                >
                              </div>
                            </div>
                          </div>
                          <div class="vx-col md:w-1/2 w-full find-people">
                            <input-tag
                              v-model="keywordToshow"
                              @input="updateKeywordTags"
                              v-validate="competitorsToshow.length == 0 ? 'required' : ''"
                              onkeypress="return false;"
                              name="keywordToshow"
                              class="h-min-100"
                            ></input-tag>
                            <span class="text-danger text-sm">{{ errors.first("prebuiltCampaignForm.keywordToshow") }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Engaged with competitors -->
                    <div class="vx-col w-full mb-1">
                      <div class="filter-campaign">
                        <h3>Engaged with Competitors</h3>
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2 w-full">
                            <div class="add-to-field">
                              <div class="float-left flex items-center w-full flex-wrap">
                                <v-select
                                  ref="competitors"
                                  class="w-full mb-3 input-color"
                                  label="Value"
                                  placeholder="Select "
                                  :options="compititorsOptions"
                                  v-model="competitorsSelected"
                                  :clearSearchOnSelect="false"
                                  @input="clearSearchedTerm('compitetor')"
                                  @keyup.native="getTiggerItemByTypeTerm('compitetor',$event)"
                                />
                                <vs-button
                                  title="Update"
                                  color="primary"
                                  class="ml-auto"
                                  type="filled"
                                  @click="requestNewTrigger('competitor')"
                                  >{{ LabelConstant.buttonLabelRequestNewCompetitor }}</vs-button
                                >
                                <vs-button
                                  title="Update"
                                  color="success"
                                  class="ml-2"
                                  type="filled"
                                  @click="addCompitotors()"
                                  >{{ LabelConstant.buttonLabelAdd }}</vs-button
                                >
                              </div>
                            </div>
                          </div>
                          <div class="vx-col md:w-1/2 w-full find-people">
                            <input-tag
                              v-model="competitorsToshow"
                              @input="updateCompetitorsTags"
                              onkeypress="return false;"
                              v-validate="keywordToshow.length == 0 ? 'required' : ''"
                              name="competitorsToshow"
                              class="h-min-100"
                            ></input-tag>
                            <span class="text-danger text-sm">{{ errors.first("prebuiltCampaignForm.competitorsToshow") }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Categories -->
                    <div class="vx-col w-full mb-1">
                      <div class="filter-campaign">
                        <h3>Categories</h3>
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2 w-full">
                            <div class="add-to-field">
                              <div class="float-left flex items-center w-full flex-wrap">
                                <v-select
                                  ref="categories"
                                  class="w-full mb-3"
                                  label="Value"
                                  placeholder="Select "
                                  :options="categoriesOption"
                                  v-model="categoriesSelected"
                                  @keyup.native="getTiggerItemByTypeTerm('category', $event)"
                                />
                                <vs-button
                                  title="Update"
                                  color="success"
                                  class="ml-auto"
                                  type="filled"
                                  @click="addCategories()"
                                  >{{ LabelConstant.buttonLabelAdd }}</vs-button
                                >
                              </div>
                            </div>
                          </div>
                          <div class="vx-col md:w-1/2 w-full find-people">
                            <input-tag
                              v-model="categoriesToShow"
                              @input="updateCategoriesTags"
                              onkeypress="return false;"
                              name="categoriesToShow"
                              class="h-min-100"
                            ></input-tag>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="vx-col w-full my-3">
                      <vs-checkbox class="mb-4 ml-0" v-model="prebuiltCampaignIsActive">Prebuilt Campaign is Active</vs-checkbox>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="text-right mt-6 mb-8">
        <vs-button
          title="Save"
          color="primary"
          type="filled"
          class="mr-2"
          @click="addEditPrebuiltCampaign()"
          >{{ LabelConstant.buttonLabelSave }}
        </vs-button>
        <vs-button
          color="grey"
          @click="cancelCampaign()"
          type="border"
          class="grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

  <script>
import vSelect from "vue-select";
import InputTag from "vue-input-tag";
import { Validator } from "vee-validate";

const dict = {
  custom: {
    addEditCategoryName: {
      required: "This field is required.",
    },
    campaignName: {
      required: "Please enter campaign name",
    },
    keywordToshow: {
      required: "Keywords are required please add keywords",
    },
    competitorsToshow: {
      required: "Competitors are required please add competitors",
    },
  },
};

Validator.localize("en", dict);

export default {
  components: {
    "v-select": vSelect,
    InputTag,
  },
  data() {
    return {
      prebuiltCampaignsList: [],
      perPage: 5,
      recordList: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],

      addCampaignPopup: false,
      addEditCampaignTitle: null,
      editMode: false,
      campaignId: 0,
      campaignName: null,
      category: null,
      description: null,

      searchWord: null,
      tempSearchWord: null,

      keywordsOptions: [],
      keywordsOptionsArray: [],
      keywordSelected: null,
      keywordAdded: [],
      keywordToshow: [],

      compititorsOptions: [],
      compititorsOptionsArray: [],
      competitorsSelected: null,
      competitorsAdded: [],
      competitorsToshow: [],

      categoriesOption: [],
      categoriesSelected: null,
      categoriesAdded: [],
      categoriesToShow: [],

      prebuiltCampaignIsActive: false,

      //Add-Edit Category
      categoryPopup: false,
      categoryList: [],
      addEditCategoryTitle: null,
      perPageCategories: 5,
      addEditCategoryName: null,
      editCategoryId: null,
      editCategoryMode: false,
      addEditCategoryPopup: false,
    };
  },
  created() {
    this.getCampaignsPreBuiltAll();
    this.getCategories();
  },
  watch: {
    keywordsOptionsArray(){
      if(this.keywordsOptionsArray.length > 0){
        this.keywordsOptions = this.keywordsOptionsArray.reduce(function(a, b) {
          return a.length <= b.length ? a : b;
        })
      }
    },
    compititorsOptionsArray(){
      if(this.compititorsOptionsArray.length > 0){
        this.compititorsOptions = this.compititorsOptionsArray.reduce(function(a, b) {
          return a.length <= b.length ? a : b;
        })
      }
    }
  },
  methods: {
    getCampaignsPreBuiltAll() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneCampaign/GetCampaignsPreBuiltAll")
        .then((response) => {
          let data = response.data;
          this.prebuiltCampaignsList = data;
          this.prebuiltCampaignsList.map(function (manipulatedData) {
            let keywordArr = [];
            for (let i = 0; i < manipulatedData.Items.length; i++) {
              if (manipulatedData.Items[i].Value) {
                keywordArr.push(manipulatedData.Items[i].Value);
              }
            }
            let keywordStr = keywordArr.toString();
            manipulatedData.keywords = keywordStr.replace(/,/g, ", ");
            return manipulatedData;
          });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getCategories() {
      this.$vs.loading();
      this.axios
        .get("/ws/FindAnyoneCampaign/GetCategories")
        .then((response) => {
          let data = response.data;
          this.categoryList = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    cancelAddEditCategory() {
      this.addEditCategoryPopup = false;
      this.addEditCategoryName = null;
      this.addEditCategoryTitle = null;
      this.editCategoryId = null;
      this.editCategoryMode = false;
      setTimeout(() => {
        this.errors.clear("addEditCategoryForm");
      }, 20);
    },
    addCategory() {
      this.$validator.validateAll("addEditCategoryForm").then((result) => {
        if (result) {
          let addCategoryParams = {
            Id: 0,
            Category_Name: this.addEditCategoryName,
          };
          this.$vs.loading();
          this.axios
            .post("/ws/FindAnyoneCampaign/AddCategory", addCategoryParams)
            .then(() => {
              this.$refs.categoryTableData.currentx = 1;
              this.$refs.categoryTableData.searchx = "";
              this.$vs.notify({
                title: "Success",
                text: "Category added.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.getCategories();
              this.cancelAddEditCategory();
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    editCategory() {
      this.$validator.validateAll("addEditCategoryForm").then((result) => {
        if (result) {
          let editCategoryParams = {
            Id: this.editCategoryId,
            Category_Name: this.addEditCategoryName,
          };
          this.$vs.loading();
          this.axios
            .post("/ws/FindAnyoneCampaign/EditCategory", editCategoryParams)
            .then(() => {
              this.$refs.categoryTableData.currentx = 1;
              this.$refs.categoryTableData.searchx = "";
              this.$vs.notify({
                title: "Success",
                text: "Category updated.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.getCategories();
              this.cancelAddEditCategory();
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    async getTiggerItemByTypeTerm(type, event) {
      if (event.keyCode != 8 && event.keyCode != 46 && event.keyCode != 32) {
        let term = "";
        if (type == "keyword") {
          term = this.$refs.keyworddd.search;
          this.searchWord = this.$refs.keyworddd.search;
        } else if (type == "category") {
          term = this.$refs.categories.search;
          this.searchWord = this.$refs.categories.search;
        } else {
          term = this.$refs.competitors.search;
          this.searchWord = this.$refs.competitors.search;
        }
        let input = {
          Type: type,
          Term: term.trim().toLowerCase(),
        };
        if (term) {
          this.$vs.loading();
          await this.axios
            .post("/ws/FindAnyoneCampaign/GetTriggerItemsByTypeTerm", input)
            .then((response) => {
              let data = response.data;
              if(data){
                if (type == "keyword") {
                  if(!this.searchWord.includes(this.tempSearchWord) || !this.tempSearchWord.includes(term)){
                    this.keywordsOptionsArray = []
                    this.tempSearchWord = this.searchWord
                  }
                  this.keywordsOptionsArray.push(data);
                } else if (type == "category") {
                  this.categoriesOption = data;
                } else {
                  if(!this.searchWord.includes(this.tempSearchWord) || !this.tempSearchWord.includes(term)){
                    this.compititorsOptionsArray = []
                    this.tempSearchWord = this.searchWord
                  }
                  this.compititorsOptionsArray.push(data);
                }
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
              this.showError(e);
            });
        }
      }else{
        this.keywordsOptionsArray = [];
        this.compititorsOptionsArray = [];
      }
    },
    addKeywords() {
      if (this.keywordSelected) {
        if (this.keywordSelected.Requested) {
          this.keywordToshow.push(this.keywordSelected.Value + " (REQUESTED)");
        } else {
          this.keywordToshow.push(this.keywordSelected.Value);
        }
        this.keywordAdded.push(this.keywordSelected);
        this.keywordSelected = null;
        this.searchWord = null;
      }
    },
    updateKeywordTags(value) {
      let arr = [];
      for (let i = 0; i < value.length; i++) {
        if (value[i].includes(" (REQUESTED)")) {
          let keyStr = value[i].substring(0, value[i].indexOf("("));
          arr.push(keyStr.trim());
        } else {
          arr.push(value[i]);
        }
      }
      this.keywordAdded.forEach((e, i) => {
        if (!arr.includes(e.Value)) {
          this.keywordAdded.splice(i, 1);
        }
      });
    },
    clearSearchedTerm(type){
      if(type == 'keyword'){
        this.$refs.keyworddd.search = ""
      }else{
        this.$refs.competitors.search = ""
      }
      this.searchWord = null;
    },
    requestNewTrigger(type) {
      if (this.searchWord) {
        let input = {
          CampaignId: this.editMode ? this.campaignId : 0,
          Type: type,
          Value: this.searchWord.trim().toLowerCase(),
        };
        this.$vs.loading();
        this.axios
          .post("/ws/FindAnyoneCampaign/RequestNewTriggerItemToCampaign", input)
          .then((response) => {
            let data = response.data;
            if (type == "keyword") {
              if (data.Value) {
                this.keywordAdded.push(data);
                this.keywordToshow.push(data.Value + " (REQUESTED)");
                this.$refs.keyworddd.search = ""
                this.$refs.competitors.search = ""
                this.searchWord = null;
              }
            } else {
              if (data.Value) {
                this.competitorsAdded.push(data);
                this.competitorsToshow.push(data.Value + " (REQUESTED)");
                this.$refs.competitors.search = ""
                this.$refs.keyworddd.search = ""
                this.searchWord = null;
              }
            }
            this.$vs.loading.close();
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
            this.showError(e);
          });
      }
    },
    updateCompetitorsTags(value) {
      let arr = [];
      for (let i = 0; i < value.length; i++) {
        if (value[i].includes(" (REQUESTED)")) {
          let keyStr = value[i].substring(0, value[i].indexOf("("));
          arr.push(keyStr.trim());
        } else {
          arr.push(value[i]);
        }
      }
      this.competitorsAdded.forEach((e, i) => {
        if (!arr.includes(e.Value)) {
          this.competitorsAdded.splice(i, 1);
        }
      });
    },
    addCompitotors() {
      if (this.competitorsSelected) {
        if (this.competitorsSelected.Requested) {
          this.competitorsToshow.push(
            this.competitorsSelected.Value + " (REQUESTED)"
          );
        } else {
          this.competitorsToshow.push(this.competitorsSelected.Value);
        }
        this.competitorsAdded.push(this.competitorsSelected);
        this.competitorsSelected = null;
        this.searchWord = null;
      }
    },
    addCategories() {
      if (this.categoriesSelected) {
        if (this.categoriesSelected.Requested) {
          this.categoriesToShow.push(this.categoriesSelected.Value + " (REQUESTED)");
        } else {
          this.categoriesToShow.push(this.categoriesSelected.Value);
        }
        this.categoriesAdded.push(this.categoriesSelected);
        this.categoriesSelected = null;
        this.searchWord = null;
      }
    },
    updateCategoriesTags(value) {
      let arr = [];
      for (let i = 0; i < value.length; i++) {
        if (value[i].includes(" (REQUESTED)")) {
          let keyStr = value[i].substring(0, value[i].indexOf("("));
          arr.push(keyStr.trim());
        } else {
          arr.push(value[i]);
        }
      }
      this.categoriesAdded.forEach((e, i) => {
        if (!arr.includes(e.Value)) {
          this.categoriesAdded.splice(i, 1);
        }
      });
    },
    cancelCampaign() {
      this.addCampaignPopup = false;
      this.addEditCampaignTitle = null;
      this.campaignId = 0;
      this.editMode = false;
      this.campaignName = null;
      this.description = null;
      this.category = null;

      this.keywordsOptions = [];
      this.keywordsOptionsArray = [];
      this.keywordSelected = null;
      this.keywordAdded = [];
      this.keywordToshow = [];
      this.compititorsOptions = [];
      this.compititorsOptionsArray = [];
      this.competitorsSelected = null;
      this.competitorsAdded = [];
      this.competitorsToshow = [];
      this.categoriesAdded = [];
      this.categoriesOption = [];
      this.categoriesSelected = null;
      this.categoriesToShow = [];
      this.searchWord = null;

      this.prebuiltCampaignIsActive = false;
      setTimeout(() => {
        this.errors.clear("prebuiltCampaignForm");
      }, 100);
    },
    async addEditPrebuiltCampaign() {
      this.$validator.validateAll("prebuiltCampaignForm")
        .then(async (result) => {
          if (result) {
            let item = [];
            if (this.keywordAdded.length > 0) {
              this.keywordAdded.forEach((element) => {
                item.push(element);
              });
            }
            if (this.competitorsAdded.length > 0) {
              this.competitorsAdded.forEach((element) => {
                item.push(element);
              });
            }
            if (this.categoriesAdded.length > 0) {
              this.categoriesAdded.forEach((element) => {
                item.push(element);
              });
            }
            let input = {
              Id: this.campaignId,
              Campaign_Name: this.campaignName,
              Description: this.description,
              Category_Id: this.category,
              Items: item,
              is_Active: this.prebuiltCampaignIsActive,
            };
            this.$vs.loading();
            let resp = await this.axios
              .post(!this.editMode ? "/ws/FindAnyoneCampaign/AddPreBuiltCampaign" : "/ws/FindAnyoneCampaign/EditPreBuiltCampaign",input)
              .then((response) => {
                let data = response.data;
                this.campaignId = data.Id;
                let successMessage = !this.editMode ? "Campaign Added." : "Campaign Updated.";
                this.$refs.prebuiltCampaignTableData.currentx = 1;
                this.$refs.prebuiltCampaignTableData.searchx = "";
                this.$vs.notify({
                  title: "Success",
                  text: successMessage,
                  color: "success",
                  iconPack: "feather",
                  icon: "icon-check",
                  position: "top-right",
                  time: 4000,
                });
                this.$vs.loading.close();
                return true;
              })
              .catch((e) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error",
                  text: e.response.data.Errors[0].Message,
                  color: "danger",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  position: "top-right",
                  time: 4000,
                });
                this.showError(e);
                return false;
              });
            if (resp) {
              if (this.editMode) {
                this.addTriggerToCampaign();
              } else {
                this.getCampaignsPreBuiltAll();
                this.cancelCampaign();
              }
            }
          }
        });
    },
    addTriggerToCampaign() {
      let trigger = [];
      if (this.keywordAdded.length > 0) {
        this.keywordAdded.forEach((e) => {
          trigger.push(e.Id);
        });
      }
      if (this.competitorsAdded.length > 0) {
        this.competitorsAdded.forEach((e) => {
          trigger.push(e.Id);
        });
      }
      if (this.categoriesAdded.length > 0) {
        this.categoriesAdded.forEach((e) => {
          trigger.push(e.Id);
        });
      }
      let input = {
        CampaignId: this.campaignId,
        TriggerIds: trigger,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/FindAnyoneCampaign/AddTriggerItemToCampaign", input)
        .then((response) => {
          let data = response.data;
          if (data.Transaction_Successful) {
            this.$vs.notify({
              title: "Success",
              text: "Triggers Added Successfully",
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
              time: 4000,
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
            });
          }
          this.getCampaignsPreBuiltAll();
          this.cancelCampaign();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    getPrebuiltCampaign(data) {
      this.campaignName = data.Campaign_Name;
      this.description = data.Description;
      this.campaignId = data.Id;
      this.category = data.Category_Id;
      this.prebuiltCampaignIsActive = data.is_Active;
      this.editMode = true;
      if (data.Items.length > 0) {
        data.Items.forEach((e) => {
          if (e.Type == "competitor") {
            this.competitorsAdded.push(e);
            if (e.Requested) {
              this.competitorsToshow.push(e.Value + " (REQUESTED)");
            } else {
              this.competitorsToshow.push(e.Value);
            }
          } else if (e.Type == "category") {
            this.categoriesAdded.push(e);
            if (e.Requested) {
              this.categoriesToShow.push(e.Value + " (REQUESTED)");
            } else {
              this.categoriesToShow.push(e.Value);
            }
          } else {
            this.keywordAdded.push(e);
            if (e.Requested) {
              this.keywordToshow.push(e.Value + " (REQUESTED)");
            } else {
              this.keywordToshow.push(e.Value);
            }
          }
        });
      }
      this.addCampaignPopup = true;
    },
  },
};
</script>